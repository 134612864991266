import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import locationHOC from '../locationProvider/locationHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import { Language } from '../../constants/propTypesDefinitions';
import { withPrefix } from 'gatsby';
import { isObject } from '../../lib/object';
import Editor from './Editor';
import withDataHOC from '../dataProvider/withDataHOC';
import InputTextArea from './InputTextArea';
import InputText from './InputText';

/**
 * Component that enables input text. onChange event is fired only
 * when return is pressed or when focus is lost.
 *
 * @fero
 */

class MultiLangInput extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        autoFocus: PropTypes.bool,
        [GLOBAL_DATA.LANGUAGES]: PropTypes.arrayOf(Language.isRequired).isRequired,
    };

    static defaultProps = {
        type: "text",
        autoFocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };
    }

    componentDidMount() {
        const {autoFocus, value, disabled} = this.props;

        if(isObject(value) && !disabled)
        {
            const keys = Object.keys(value);
            if(keys.length > 1)
                this.setState({isExpanded: true});
        }

        if(autoFocus)
        {
            setTimeout(() => {
                this.focus();
            }, 0)
        }
    }

    focus() {
        if(this.first != null && this.first.focus != null) 
            this.first.focus();
    }

    onChanged = (lang) => (newVal) => {
        const {value, [SESSION_ATTRIBUTES.SETTINGS]: settings, onChange} = this.props;
        let val = {};

        if(isObject(value))
        {
            // leave as it is
            val = value;
        }
        else
        {
            // singleton is default lang
            val = {
                [settings.id_default_language]: value,
            };
        }

        val[lang] = newVal;

        if(onChange != null)
            onChange(val);
    };

    getValue = (lang) => {
        const {value, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        if(isObject(value))
            return value[lang];
        else if(lang == settings.id_default_language)
            return value;
        else
            return null;
    };

    toggleExpand = () => {
        const {isExpanded} = this.state;
        this.setState({isExpanded: !isExpanded});
    };

    render() {
        const {value, onChange, type, autoFocus, placeholder, allowClear, disabled, className,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
            [GLOBAL_DATA.LANGUAGES]: languages} = this.props;
        const {isExpanded} = this.state;
        const languagesChecked = languages != null && Array.isArray(languages) ? languages : [];
        const defaultLang = languagesChecked.find(l => l.id == settings.id_default_language);
        const otherLanguages = languagesChecked.filter(l => l.id != settings.id_default_language);
        const Component = type == 'html' ? Editor : (type == 'textarea' ? InputTextArea : InputText);
        if(defaultLang == null)
        {
            // fallback to default
            return <Component
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={className}
                allowClear={allowClear}
                disabled={disabled}
                autoFocus={autoFocus}
            />;
        }

        return <div className="full-size-width">
            <div className="d-flex pb-1">
                <Component
                    onChange={this.onChanged(defaultLang.id)}
                    type={type}
                    value={this.getValue(defaultLang.id)}
                    ref={node => this.first = node}
                    placeholder={placeholder}
                    className={className}
                    allowClear={allowClear}
                    disabled={disabled}
                />
                <img 
                    src={withPrefix(`/images/locale/${defaultLang.id}.png`)}
                    className="ml-1 mt-2"
                    height="18px"
                    onClick={this.toggleExpand}
                />
            </div>
            { isExpanded ? 
                otherLanguages.map((lang) => {
                    return <div className="d-flex pb-1"  key={lang.id}>
                        <Component
                            allowClear={true}
                            onChange={this.onChanged(lang.id)}
                            type={type}
                            value={this.getValue(lang.id)}
                            placeholder={placeholder}
                            className={className}
                            disabled={disabled}
                        />
                        <img 
                            src={withPrefix(`/images/locale/${lang.id}.png`)}
                            height="18px"
                            className="ml-1 mt-2"
                        />
                    </div>
                })
                : null
            }
        </div>;
    }

}

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.LANGUAGES])(MultiLangInput)
    )
);