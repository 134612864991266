import React from 'react';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {CustomerGroups} from '../../../constants/propTypesDefinitions';
import CustomerGroupEdit from './CustomerGroupEdit';
import CustomerGroupRemove from './CustomerGroupRemove';

/**
 * @dusan
 */

class CustomerGroupList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroupId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.CUSTOMER_GROUPS]: CustomerGroups.isRequired,
    };

    onSelectGroup = (groupId) => {
        this.setState({
            selectedGroupId: groupId,
        })
    };

    render() {
        const {selectedGroupId} = this.state;
        const groups = this.props[GLOBAL_DATA.CUSTOMER_GROUPS];
        return <List
                values={groups}
                onItemClick={(value) => {
                    this.onSelectGroup(value.id);
                }}
                selectedValueId={selectedGroupId}
            >
                {groups.map((group) => {
                    return <div key={group.id} className="d-flex flex-wrap align-items-center">
                        {group.name}
                        {group.id == selectedGroupId ? [
                            <CustomerGroupEdit
                                key="edit"
                                group={group}
                            />,
                            <CustomerGroupRemove
                                key="remove"
                                group={group}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.CUSTOMER_GROUPS])(CustomerGroupList);